import { useEffect, useState } from 'react'
import Footer from 'swp-components/lib/components/footer'
import { DataLayerProvider } from 'swp-components/lib/contexts/data-layer'

import ChatShop from '@/components/chat-shop'
import CookiesConsent from '@/components/cookies-consent'
import Header from '@/components/header'
import Seo from '@/components/seo'
import TrueXZone from '@/components/truex-zone'
import useTrueXPageDataLayer from '@/hooks/data-layers/use-true-x-page'
import NotFoundPage from '@/pages/404'
import strapi from '@/strapi'

const TrueXSubCat = ({ serverData, locale, slug }) => {
  let [
    headerScript,
    brandHeaders,
    productServices,
    footerHeaders,
    chatShop,
    trueX,
    mariConfig,
  ] = strapi.parseContents(serverData.contents)
  const [componentDataClean, setComponentDataClean] = useState([])

  let rawServerData = trueX[0] ?? null

  if (rawServerData === null) {
    return <NotFoundPage />
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    let _componentDataClean = []
    rawServerData.componentZone.forEach(component => {
      if (component.status) {
        _componentDataClean.push(component)
      }
    })
    setComponentDataClean(_componentDataClean)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // eslint-disable-next-line react-hooks/rules-of-hooks
  let { handleMessage, createScopeProps } = useTrueXPageDataLayer(slug)

  return (
    <DataLayerProvider handler={handleMessage}>
      <Seo seo={rawServerData.seo} script={headerScript.script} />
      <Header items={brandHeaders} services={productServices} />
      {componentDataClean.map(comp => (
        <TrueXZone
          key={`${comp.component}:${comp.id}`}
          name={comp.component}
          comp={comp}
          createScopeProps={createScopeProps}
        ></TrueXZone>
      ))}
      <Footer className="pb-4" items={footerHeaders} />
      <ChatShop
        content={chatShop}
        mariPlatform={ serverData.locale == 'th' ? "mari_tmh" : "truemoveh" }
        mariConfig={mariConfig}
      />
      <CookiesConsent />
    </DataLayerProvider>
  )
}

export default TrueXSubCat

export const getServerData = async ({ params, pageContext }) => {
  let contents = await strapi.getContents([
    `header-script?_locale=${pageContext.locale}`,
    `brand-headers?_locale=${pageContext.locale}`,
    `product-services?_locale=${pageContext.locale}`,
    `footer-headers?_locale=${pageContext.locale}`,
    `chat-and-shop?_locale=${pageContext.locale}`,
    `true-x-master-pages?slug=${params.slug}&_locale=${pageContext.locale}`,
    'mari-chat-and-shop-config',
  ])

  return {
    props: { contents, locale: pageContext.locale, slug: params.slug },
  }
}
